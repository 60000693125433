import React, {useEffect, useRef, useState} from 'react';
import ReactTooltip from 'react-tooltip';

function FilterBar({filterAction, overRidePostcode, overRideDistance}) {

    const postcodeRef = useRef();

    const [filters, setFilters] = useState({
        OrtPLZ: '',
        showAll: new URLSearchParams(window.location.search).get('oedemversorgung') === 'yes' ? false : true,
        flachStrich: new URLSearchParams(window.location.search).get('oedemversorgung') === 'yes' ? true : false,
        messystem3D: false,
        upTo5km: true,
        upTo15km: false,
        moreThen15km: false,
    });

    const [isInvalid, setIsInvalid] = useState(false);


    useEffect(() => {
        //console.log('!!!!!!!!!!!!!!!!!!!FilterBar useEffect [overRidePostcode]', overRidePostcode);
        postcodeRef.current.value = overRidePostcode;
        setFilters({...filters, OrtPLZ: overRidePostcode});
        // TODO update state
    }, [overRidePostcode])

    useEffect(() => {
        //console.log('!!!!!FilterBar useEffect [overRideDistance]', overRideDistance);
        const distanceFilters = {
            upTo5km: true,
            upTo15km: false,
            moreThen15km: false,
        }
        if (overRideDistance <= 15 && overRideDistance > 5) {
            distanceFilters.upTo5km = false;
            distanceFilters.upTo15km = true;
            distanceFilters.moreThen15km = false;
        }
        if (overRideDistance > 15) {
            distanceFilters.upTo5km = false;
            distanceFilters.upTo15km = false;
            distanceFilters.moreThen15km = true;
        }
        setFilters({...filters, ...distanceFilters});
    }, [overRideDistance])

    const validatePostcode = (postcodeOrPlace) => {
        //console.log('validatePostcode() postcodeOrPlace.length', postcodeOrPlace.length);
        //console.log('validatePostcode() Number(postcodeOrPlace)', Number(postcodeOrPlace));
        //console.log('validatePostcode() (Number(postcodeOrPlace)===NaN && postcodeOrPlace.length > 3)', (isNaN(postcodeOrPlace)));

        return ((Number.isInteger(Number(postcodeOrPlace)) && postcodeOrPlace.length === 5) || (isNaN(postcodeOrPlace) && postcodeOrPlace.length > 3));
    }

    useEffect(() => {
        //console.log('FilterBar - useEffect [filters],filters,isInvalid', filters,isInvalid);
        if(!isInvalid && filters.OrtPLZ.length > 4){
            // TODO uncomment next line to enable direct search when a filter is clicked without additional FINDEN button klick
            submitForm(new Event('submit'));
        }
    }, [filters])

    const submitForm = (event) => {
        event.preventDefault();
        //console.log('FilterBar - submitForm');
        setIsInvalid(false);
        const locationSearchStr = postcodeRef.current.value.trim();
        if (validatePostcode(locationSearchStr)) {
            filterAction({...filters, OrtPLZ: locationSearchStr});
        } else {
            console.warn('This is Not a valid place search!');
            setIsInvalid(true);
        }
    }

    const changeFilter = (filter, value) => {
        //console.log('FilterBar - changeFilter');
        //console.log('filter', filter);
        processFilterLogic(filter, value);
    }

    const processFilterLogic = (filter, value) => {
        //console.log('FilterBar.processFilterLogic(filter, value)', filter, value);
        let currentFilters = {...filters};
        switch (filter) {
            case 'upTo5km' :
                currentFilters = {...currentFilters, upTo5km: true, upTo15km: false, moreThen15km: false};
                break;
            case 'upTo15km' :
                currentFilters = {...currentFilters, upTo5km: false, upTo15km: true, moreThen15km: false};
                break;
            case 'moreThen15km' :
                currentFilters = {...currentFilters, upTo5km: false, upTo15km: false, moreThen15km: true};
                break;
            case 'showAll' :
                currentFilters = {...currentFilters, showAll: true, messystem3D: false, flachStrich: false};
                break;
            case 'flachStrich' :
                currentFilters = {...currentFilters, showAll: false, flachStrich: value};
                break;
            case 'messystem3D' :
                currentFilters = {...currentFilters, showAll: false, messystem3D: value};
                break;
            default :
                currentFilters = {...currentFilters, [filter]: value};
                break;
        }
        setFilters({...currentFilters});
    }

    const infos = (topic) => {
        if (topic === 'Flachstrich') {
            return `<h3>Lymph-/Lipödemversorgung</h3> 
Moderne flachgestrickte Kompressionsstrümpfe für die Lymph- und Lipödemtherapie finden Sie in diesen BELSANA Apotheken mit ausgebildetem Fachpersonal.
`;
        } else if (topic === '3DMesssystem') {
            return `<h2>3DMesssystem</h2> 
Schnelles und berührungsloses Anmessen von medizinischen Kompressionsstrümpfen: Finden Sie BELSANA Apotheken mit digitalem 3D-Messsystem mit Laserscan.
`;
        }

        return 'YEY';
    }

/*    const mockInput = () => {
        //  postcodeRef.current.value='40219';
        postcodeRef.current.value = '90402';
        submitForm(new Event('submit'));
    }*/

    return (
        <div className="w-full bg-white shadow lg:sticky lg:z-100 lg:top-13 ">
            <div id="filter" className="filterbar max-w-m 2xl:mx-40 py-3">

                <ReactTooltip clickable={true} multiline={true} html={true} globalEventOff={'click'} place="bottom" />
                <form onSubmit={submitForm} className="space-y-4 p-4 text-black">
                    <div className="grid gap-4 lg:gap-2 grid-col-1 lg:grid-flow-col lg:auto-cols-auto">
                        {/*             <button onClick={mockInput} className="text-xs bg-belsana text-white p-1 m-2 transform scale-75">90402</button>*/}
                        <div className="location-search relative mx-auto lg:mx-0">

                            <input type="text"
                                   placeholder="PLZ oder Stadt"
                                   ref={postcodeRef}
                                   defaultValue={filters['OrtPLZ']}
                                   className={
                                       isInvalid ? 'search-field lg:w-320 h-12 border-2 border-red-700 focus:border-opacity-100 bg-white py-5 pl-14 pr-8 block outline-none placeholder-gray-400 text-lg ' : 'search-field lg:w-320 h-12 border border-belsana border-opacity-25 focus:border-opacity-100 bg-white py-5 pl-14 pr-8 block outline-none placeholder-gray-400 text-lg '
                                   }
                            />
                            <i className="absolute top-3 left-6">
                                <svg className="w-5.5 h-5.5 text-belsana" viewBox="0 0 20 30" xmlns="http://www.w3.org/2000/svg"
                                     fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2">
                                    <path
                                        d="M2889 394.877c.07-10.43 8.55-18.877 19-18.877 10.49 0 19 8.514 19 19v.038c.02 10.921-7.66 25.33-19 39.962-11.36-14.65-19.04-29.075-19-40v-.123Zm19-10.377c5.8 0 10.5 4.705 10.5 10.5s-4.7 10.5-10.5 10.5-10.5-4.705-10.5-10.5 4.7-10.5 10.5-10.5Z"
                                        fill="#375988" transform="matrix(.5 0 0 .5 -1444.5 -188)"/>
                                </svg>
                            </i>
                            {isInvalid &&
                            <div className="text-red-700 text-error text-xs absolute -bottom-4">Postleitzahl oder Ort
                                ist nicht korrekt.</div>}
                        </div>

                        <div
                            className="grid grid-cols-2 gap-x-8 gap-y-2 lg:grid-cols-2 2xl:grid-cols-3 grid-flow-row mx-auto lg:mx-0 pl-4">
                            <div className="flex items-center">
                                <label htmlFor="filterAll" className="flex items-center whitespace-nowrap">
                                    <input id="filterAll" type="checkbox" checked={filters['showAll']}
                                           onChange={(event) => {
                                               changeFilter('showAll', !filters['showAll'])
                                           }}
                                           className="mr-2 checked:bg-belsana text-belsana rounded  "/>
                                    Alle ansehen
                                </label>
                            </div>
                            <div className="flex items-center">
                                <label htmlFor="filter-2" className=" whitespace-nowrap">
                                    <input id="filter-2" type="checkbox" checked={filters['flachStrich']}
                                           onChange={(event) => {
                                               changeFilter('flachStrich', !filters['flachStrich'])
                                           }}
                                           className="mr-2 bg-white checked:bg-belsana rounded text-belsana relative -top-px"/>
                                    Ödemversorgung
                                </label>
                                <i data-tip={infos('Flachstrich')} data-multiline={true} data-class="belsana-tooltip"
                                   data-html={true} data-event="click" data-background-color="#fff"
                                   data-border-color="#000"
                                   data-border={true} data-text-color="black"
                                   className="inline-block ml-2 ">
                                    <svg fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2"
                                         clipRule="evenodd" viewBox="0 0 20 20" className="w-5.5 h-5.5 ">
                                        <path fill="currentColor"
                                              d="M9.76 19.54c-5.4 0-9.8-4.37-9.8-9.76S4.36.01 9.76.01s9.7 4.38 9.7 9.77-4.3 9.76-9.7 9.76Zm0-1.06c-4.3 0-8.7-4.45-8.7-8.7 0-4.26 4.4-8.71 8.7-8.71 4.2 0 8.7 4.45 8.7 8.71 0 4.25-4.5 8.7-8.7 8.7Zm-.9-10.31-.2 6.75h2.1l-.2-6.75h-1.7Zm.8-3.37c-.6 0-1.1.5-1.1 1.19 0 .72.5 1.2 1.1 1.2.6 0 1.1-.48 1.1-1.2 0-.69-.4-1.19-1.1-1.19Z"/>
                                    </svg>
                                </i>
                            </div>


                            <div className="flex items-center">
                                <label htmlFor="filter-3" className="whitespace-nowrap">
                                    <input checked={filters['messystem3D']} onChange={(event) => {
                                        changeFilter('messystem3D', !filters['messystem3D'])
                                    }} id="filter-3" type="checkbox"
                                           className="mr-2 checked:bg-belsana rounded text-belsana relative -top-px"/>
                                    3D Messsystem
                                </label>
                                <i data-tip={infos('3DMesssystem')} data-multiline={true} data-class="belsana-tooltip"
                                   data-html={true} data-event="click" data-background-color="#fff"
                                   data-border-color="#000"
                                   data-border={true} data-text-color="black"
                                   className="inline-block ml-2 ">
                                    <svg fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2"
                                         clipRule="evenodd" viewBox="0 0 20 20" className="w-5.5 h-5.5 ">
                                        <path fill="currentColor"
                                              d="M9.76 19.54c-5.4 0-9.8-4.37-9.8-9.76S4.36.01 9.76.01s9.7 4.38 9.7 9.77-4.3 9.76-9.7 9.76Zm0-1.06c-4.3 0-8.7-4.45-8.7-8.7 0-4.26 4.4-8.71 8.7-8.71 4.2 0 8.7 4.45 8.7 8.71 0 4.25-4.5 8.7-8.7 8.7Zm-.9-10.31-.2 6.75h2.1l-.2-6.75h-1.7Zm.8-3.37c-.6 0-1.1.5-1.1 1.19 0 .72.5 1.2 1.1 1.2.6 0 1.1-.48 1.1-1.2 0-.69-.4-1.19-1.1-1.19Z"/>
                                    </svg>
                                </i>
                            </div>
                            <div className="flex items-center">
                                <label htmlFor="km4" className=" inline-block whitespace-nowrap ">
                                    <input checked={filters['upTo5km']} onChange={(event) => {
                                        changeFilter('upTo5km', !filters['upTo5km'])
                                    }} id="km4" type="checkbox"
                                           className=" mr-2 bg-white checked:bg-belsana rounded text-belsana relative -top-px"/>
                                    bis 5 km
                                </label>

                            </div>


                            <div className="flex items-center">
                                <label htmlFor="km5" className="block whitespace-nowrap">
                                    <input checked={filters['upTo15km']} onChange={(event) => {
                                        changeFilter('upTo15km', !filters['upTo15km'])
                                    }} id="km5" type="checkbox"
                                           className="mr-2 checked:bg-belsana rounded text-belsana relative -top-px"/>
                                    bis 15 km
                                </label>
                            </div>
                            <div className="flex items-center">
                                <label htmlFor="km6" className=" block whitespace-nowrap">
                                    <input checked={filters['moreThen15km']} onChange={(event) => {
                                        changeFilter('moreThen15km', !filters['moreThen15km'])
                                    }} id="km6" type="checkbox"
                                           className=" mr-2 bg-white checked:bg-belsana rounded text-belsana relative -top-px"/>
                                    bis 40 km
                                </label>
                            </div>


                        </div>

                        <div
                            className="submit border border-r-0 border-t-0 border-b-0  border-l border-belsana border-opacity-25 text-center">
                            <input type="submit"
                                   className="h-12 ml-4 text-center inline-block bg-belsana text-white px-14 inline-block "
                                   value=" Finden"/>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default FilterBar;