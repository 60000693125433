import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import ReactShadowRoot from 'react-shadow-root';
import AppBelsanaApothenFinder from './AppBelsanaApothenFinder';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
      <ReactShadowRoot mode={'open'}  >
          <link rel="stylesheet" href={window.location.href.includes('localhost') ? 'tw-bundle.css' :'/_Resources/Static/Packages/permanent.storefinder/apothekenfinder-2021/build/tw-bundle.css'} />
          <AppBelsanaApothenFinder />
      </ReactShadowRoot>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
