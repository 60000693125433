import ReactTooltip from 'react-tooltip';
import React from "react";

function LocationsGrid({dataProvider}) {

    const infos = (topic) => {
        if (topic === 'VFC') {
            return `<img src="./img/tooltip-vfc-b.svg" alt="Venenfachcenter" class="w-36 mb-4">
  In über 500 VenenFachCentern erwarten Sie darüber hinaus von BELSANA ausgebildete VenenFachBerater/-innen, die ein ebenso geschultes wie offenes Ohr für Ihr Anliegen haben.
`;
        }
        if (topic === '3DMesssystem') {
            return `<h2>3DMesssystem</h2> 
Schnelles und berührungsloses Anmessen von medizinischen Kompressionsstrümpfen: Finden Sie BELSANA Apotheken mit digitalem 3D-Messsystem mit Laserscan.
`;
        }
        if (topic === 'Flachstrich') {
            return `<h3>Lymph-/Lipödemversorgung</h3> 
Moderne flachgestrickte Kompressionsstrümpfe für die Lymph- und Lipödemtherapie finden Sie in diesen BELSANA Apotheken mit ausgebildetem Fachpersonal.
`;
        }
        return true;
    }

    return (
        <div>
            <ReactTooltip clickable={true} multiline={true} html={true} event={'click'} className={'belsana-tooltip'}
                          globalEventOff={'click'}/>
            <div className="cards-grid grid gap-4  p-4 mt-10 bg-belsana-gray ">
                {(dataProvider && dataProvider.length > 0) && dataProvider.map((location, index) => <div key={index}
                                                                                                         className="apotheke-card |   bg-white"
                                                                                                         data-kundennr={location['kundennummer'].trim()}>
                    <div
                        className={location.vfc === '5' ? 'is-vfc bg-belsana text-white p-7 relative h-full  flex flex-col justify-between' : 'bg-white text-black relative p-7  h-full  flex flex-col justify-between'}>
                        <div className="relative space-y-1">
                            <h3 className='font-bold truncate '>{location.apotheke}</h3>
                            <p className="max-h-10 ">{location.strasse}, {location.plz} {location.ort}</p>
                            <a href={`https://www.google.com/maps/dir/current+location/${location.location.lat},${location.location.lon}`}
                               className=" flex items-center underline " target="_new" title="Anfahrt / Navigation">
                                <i className="inline-block mr-2">
                                    <svg
                                        className={location.vfc === '5' ? 'w-5.5 h-5.5  text-white' : "w-5.5 h-5.5  text-belsana"}
                                        xmlns="http://www.w3.org/2000/svg" fillRule="evenodd"
                                        strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="1.5"
                                        clipRule="evenodd" viewBox="0 0 23 23">
                                        <path fill="currentColor"
                                              d="M22.047 12.453c.66-.66.654-1.737-.007-2.398L12.48.494a1.69 1.69 0 0 0-2.39.001L.522 10.061a1.69 1.69 0 0 0-.001 2.39l9.561 9.561a1.7 1.7 0 0 0 2.398.007l9.566-9.566Z"/>
                                        <path fill="none" stroke={location.vfc === '5' ? '#375988' : "#fff"}
                                              strokeWidth="1.179"
                                              d="M7.114 15.33v-4.383h10.258L14.27 7.785l3.1 3.162-3.136 3.35"/>
                                    </svg>
                                </i>
                                ca. {location.distance}km</a>
                            <a href={`tel:${location.telefon}`} className="pt-4 flex underline">
                                <i className="inline-block mr-2">
                                    <svg
                                        className={location.vfc === '5' ? 'w-5.5 h-5.5  text-white' : 'w-5.5 h-5.5  text-belsana'}
                                        xmlns="http://www.w3.org/2000/svg" fillRule="evenodd"
                                        strokeLinejoin="round" strokeMiterlimit="2" clipRule="evenodd"
                                        viewBox="0 0 18 15">
                                        <path fill="currentColor"
                                              d="M2.186.6A1.478 1.478 0 0 1 4.252.296l2.37 1.762c.653.485.791 1.412.305 2.066L5.368 6.22c-1.707 2.364 3.493 6.436 5.324 3.957l1.559-2.097a1.476 1.476 0 0 1 2.066-.304l2.37 1.762c.653.486.79 1.412.304 2.066l-1.559 2.097c-.07.11-.145.21-.23.302a1.326 1.326 0 0 1-.274.27c-1.956 1.633-6.266-.062-9.733-2.611C1.795 9.16-.72 6.017.165 3.548c.042-.202.127-.4.26-.578L.6 2.732l.026-.035L2.187.6Z"/>
                                    </svg>
                                </i>
                                {location.telefon}</a>
                            {/*                            <p className=" flex align-center">
                                <i className="inline-block mr-2">
                                    <svg className={location.vfc === '5' ? 'w-5.5 h-5.5  text-white' : 'w-5.5 h-5.5  text-belsana' } fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                              d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"></path>
                                    </svg>
                                </i>
                                {location.fax}</p>*/}
                            {
                                location.homepage.length > 5 &&
                                <p className=" flex">
                                    <i className="inline-block mr-2">
                                        <svg
                                            className={location.vfc === '5' ? 'w-5.5 h-5.5  text-white' : 'w-5.5 h-5.5  text-belsana'}
                                            fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"></path>
                                        </svg>
                                    </i>
                                    <a href={'https://' + location.homepage}
                                       className='whitespace-nowrap overflow-ellipsis overflow-hidden underline  '
                                       target="_new"
                                       title="Website anzeigen">{location.homepage}</a>
                                </p>
                            }
                        </div>
                        {
                            (location.vfc !== '' || location.flachstrick !== 'N' || location.messsystem !== 'N') &&
                            <footer className="pt-7 space-y-1">
                                {
                                    location.vfc === '5' &&

                                    <div className="flex align-center mb-4">
                                        <img src='./img/icons/logo-vfc-white.svg' alt="Venenfachcenter"
                                             className=" w-36 object-contain inline-block mr-2"/>
                                        <button className="inline-block mr-2" data-tip={infos('VFC')}
                                                data-multiline={true}
                                                data-class="belsana-tooltip"
                                                data-html={true} data-event="click" data-background-color="#fff"
                                                data-border-color="#000"
                                                data-border={true} data-text-color="black">
                                            <svg fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2"
                                                 clipRule="evenodd" viewBox="0 0 20 20" className="w-5.5 h-5.5 ">
                                                <path fill="currentColor"
                                                      d="M9.76 19.54c-5.4 0-9.8-4.37-9.8-9.76S4.36.01 9.76.01s9.7 4.38 9.7 9.77-4.3 9.76-9.7 9.76Zm0-1.06c-4.3 0-8.7-4.45-8.7-8.7 0-4.26 4.4-8.71 8.7-8.71 4.2 0 8.7 4.45 8.7 8.71 0 4.25-4.5 8.7-8.7 8.7Zm-.9-10.31-.2 6.75h2.1l-.2-6.75h-1.7Zm.8-3.37c-.6 0-1.1.5-1.1 1.19 0 .72.5 1.2 1.1 1.2.6 0 1.1-.48 1.1-1.2 0-.69-.4-1.19-1.1-1.19Z"/>
                                            </svg>
                                        </button>
                                    </div>
                                }
                                <div className="flex gap-6">
                                    {
                                        location.flachstrick === 'J' &&

                                        <div className="flex align-center">
                                            <span>Ödemversorgung</span>
                                            <button className="inline-block ml-2" data-tip={infos('Flachstrich')}
                                                    data-multiline={true}
                                                    data-class="belsana-tooltip"
                                                    data-html={true} data-event="click" data-background-color="#fff"
                                                    data-border-color="#000"
                                                    data-border={true} data-text-color="black">
                                                <svg fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2"
                                                     clipRule="evenodd" viewBox="0 0 20 20" className="w-5.5 h-5.5 ">
                                                    <path fill="currentColor"
                                                          d="M9.76 19.54c-5.4 0-9.8-4.37-9.8-9.76S4.36.01 9.76.01s9.7 4.38 9.7 9.77-4.3 9.76-9.7 9.76Zm0-1.06c-4.3 0-8.7-4.45-8.7-8.7 0-4.26 4.4-8.71 8.7-8.71 4.2 0 8.7 4.45 8.7 8.71 0 4.25-4.5 8.7-8.7 8.7Zm-.9-10.31-.2 6.75h2.1l-.2-6.75h-1.7Zm.8-3.37c-.6 0-1.1.5-1.1 1.19 0 .72.5 1.2 1.1 1.2.6 0 1.1-.48 1.1-1.2 0-.69-.4-1.19-1.1-1.19Z"/>
                                                </svg>
                                            </button>
                                        </div>
                                    }
                                    {
                                        location.messsystem === 'J' &&

                                        <div className="flex align-center">
                                            <span>3DMesssystem</span>
                                            <button className="inline-block ml-2" data-tip={infos('3DMesssystem')}
                                                    data-multiline={true}
                                                    data-class="belsana-tooltip"
                                                    data-html={true} data-event="click" data-background-color="#fff"
                                                    data-border-color="#000"
                                                    data-border={true} data-text-color="black">
                                                <svg fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2"
                                                     clipRule="evenodd" viewBox="0 0 20 20" className="w-5.5 h-5.5 ">
                                                    <path fill="currentColor"
                                                          d="M9.76 19.54c-5.4 0-9.8-4.37-9.8-9.76S4.36.01 9.76.01s9.7 4.38 9.7 9.77-4.3 9.76-9.7 9.76Zm0-1.06c-4.3 0-8.7-4.45-8.7-8.7 0-4.26 4.4-8.71 8.7-8.71 4.2 0 8.7 4.45 8.7 8.71 0 4.25-4.5 8.7-8.7 8.7Zm-.9-10.31-.2 6.75h2.1l-.2-6.75h-1.7Zm.8-3.37c-.6 0-1.1.5-1.1 1.19 0 .72.5 1.2 1.1 1.2.6 0 1.1-.48 1.1-1.2 0-.69-.4-1.19-1.1-1.19Z"/>
                                                </svg>
                                            </button>
                                        </div>
                                    }
                                </div>
                            </footer>
                        }
                    </div>

                </div>)}
            </div>
        </div>
    );
}

export default LocationsGrid;