import React, {forwardRef, useCallback, useEffect, useRef, useState} from 'react';
import {QueryClient, QueryClientProvider, useQuery,} from 'react-query';

import {GoogleMapsProvider} from '@ubilabs/google-maps-react-hooks';

import FilterBar from './components/FilterBar';
import LocationsMap from './components/LocationsMap';
import LocationsGrid from './components/LocationsGrid';

//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
//import {RevolvingDot
//} from 'react-loader-spinner';
import DotLoader from "react-spinners/ClipLoader";

const GOOGLE_MAPS_API_KEY = "AIzaSyDcvhZjF0kXn25iE_VHTH7ym6cQXa86uLw";

let GOOGLE_MAPS_ENABLED = true;

const mapStyles = [{
    "featureType": "administrative", "elementType": "labels.text.fill", "stylers": [{
        "color": "#375988"
    }]
}, {
    "featureType": "landscape", "elementType": "all", "stylers": [{
        "color": "#f2f2f2"
    }]
}, {
    "featureType": "poi", "elementType": "all", "stylers": [{
        "visibility": "off"
    }]
}, {
    "featureType": "road", "elementType": "all", "stylers": [{
        "saturation": -100
    }, {
        "lightness": 45
    }]
}, {
    "featureType": "road.highway", "elementType": "all", "stylers": [{
        "visibility": "simplified"
    }]
}, {
    "featureType": "road.arterial", "elementType": "labels.icon", "stylers": [{
        "visibility": "off"
    }]
}, {
    "featureType": "transit", "elementType": "all", "stylers": [{
        "visibility": "off"
    }]
}, {
    "featureType": "water", "elementType": "all", "stylers": [{
        "color": "#c0d0e7"
    }, {
        "visibility": "on"
    }]
}];

const queryClient = new QueryClient();

const MapCanvas = forwardRef((props, ref) => (<div className="h-full" ref={ref}/>));

function AppBelsanaApothenFinder() {
    const [searchFilters, setSearchFilters] = useState({
        OrtPLZ: '', distance: '5 km', flachStrich: false, messystem3D: false,
    });

    const [isEmptySearch, setIsEmptySearch] = useState(true);
    const [currentRadius, setCurrentRadius] = useState(5);

    //const [gmapsIsEnabled,setGmapsIsEnabled] = useState(true);


    const observeCallback = (entries) => {
        //console.log('observeCallback',entries);
        if (entries[0].isIntersecting) {
            //console.log('isIntersecting',entries[0]);
            if (entries[0].intersectionRatio !== 1) {
                document.querySelector('#scrollToFilter').classList.remove('hidden');
            } else {
                document.querySelector('#scrollToFilter').classList.add('hidden');
            }

        }
    }

    /*    const initFilterObserver = () => {
            const filterBarElem = document.querySelector('.filterbar');
            //console.log('AppBelsanaApothenFinder --- initFilterObserver()',filterBarElem);

            const options = {
                rootMargin : '0px',
                threshold : [0,0.5,1]
            }
            const observer = new IntersectionObserver(observeCallback,options);
            setTimeout( () => {
             //   observer.observe(document.querySelector('.filterbar')) ;
            },1000);
        }*/

/*    const checkDSVGOCookies = () => {
        console.log('checkDSVGOCookies()');
        // Get the cookie string
        const cookieString = document.cookie;
        console.log('cookieString', cookieString);
        // Find the "CookieConsent" cookie
        const cookieConsentStart = cookieString.indexOf("CookieConsent=");
        console.log('cookieConsentStart', cookieConsentStart);
        if (cookieConsentStart !== -1) {
            const cookieConsentEnd = cookieString.indexOf(";", cookieConsentStart);
            console.log('cookieConsentEnd', cookieConsentEnd);
            const cookieConsentValue = cookieString.substring(cookieConsentStart + "CookieConsent=".length, cookieConsentEnd === -1 ? undefined : cookieConsentEnd);
            console.log('cookieConsentValue', cookieConsentValue);
            console.log(' decodeURIComponent(cookieConsentValue)', decodeURIComponent(cookieConsentValue));

            console.log('--------------');
            const inputString = decodeURIComponent(cookieConsentValue);
            // Regular expression to match the value after "marketing:"
            const regex = /marketing:(\w+)/;
            const match = inputString.match(regex);

            if (match && match[1] === "true") {
                console.log("The value after 'marketing:' is 'true'");
                return true;
            } else {
                console.log("The value after 'marketing:' is not 'true'");
                return false;
            }

        } else {
            console.log("CookieConsent cookie not found");
            return false;
        }

    }*/

    useEffect(() => {
        console.log('AppBelsanaApothenFinder - useEffect [] - app entry point');
        //GOOGLE_MAPS_ENABLED = checkDSVGOCookies();
       // setGmapsIsEnabled(checkDSVGOCookies());
    }, []);

/*    useEffect(() => {
        console.log('AppBelsanaApothenFinder - useEffect [] -[gmapsIsEnabled] gmapsIsEnabled.enabled,GOOGLE_MAPS_ENABLED before:', gmapsIsEnabled.enabled, GOOGLE_MAPS_ENABLED);
        GOOGLE_MAPS_ENABLED = gmapsIsEnabled.enabled;
        console.log('AppBelsanaApothenFinder - useEffect [] -[gmapsIsEnabled] gmapsIsEnabled.enabled,GOOGLE_MAPS_ENABLED after:', gmapsIsEnabled.enabled, GOOGLE_MAPS_ENABLED);
    }, [gmapsIsEnabled]);*/

    useEffect(async () => {
        console.log('useEffect[searchFilters] ', searchFilters);
        await queryClient.invalidateQueries(['locations'], {
            refetchInactive: true
        });
        // focusManager.setFocused(true);
        //console.log('Query Invalidated');
    }, [searchFilters]);


    const filterData = async (filters) => {
        // console.log('AppBelsanaApothenFinder - filterData(filters)');
        //     console.log('filters', filters);
        setIsEmptySearch(false);
        let distance = '5 km';
        if (filters['upTo5km']) {
            distance = '5 km';
        } else if (filters['upTo15km']) {
            distance = '15 km';
        } else if (filters['moreThen15km']) {
            distance = '40 km';
        }
        setSearchFilters({
            ...searchFilters,
            OrtPLZ: filters['OrtPLZ'],
            distance: distance,
            flachStrich: filters['flachStrich'],
            messystem3D: filters['messystem3D']
        });
        //  //console.log('Changed searchFilters', searchFilters);
    }

    const updatePostcodeFilter = (postcode) => {
        //console.log('AppBelsanaApothenFinder . updatePostcodeFilter(postcode)',postcode);
        setSearchFilters({...searchFilters, OrtPLZ: postcode});
    }

    const increaseRadius = (radius) => {
        //console.log('AppBelsanaApothenFinder . increaseRadius(radius)!!!!!!!!!!!',radius);
        if (radius <= 40) {
            setSearchFilters({...searchFilters, distance: `${radius} km`});
            setCurrentRadius(radius);
        }
    }

    return (

        <div className="AppBelsanaApothenFinder">
            <FilterBar filterAction={filterData} overRidePostcode={searchFilters.OrtPLZ}
                       overRideDistance={currentRadius}/>
            <a id="scrollToFilter" className="hidden mt-2 block fixed  top-14  w-screen z-50 lg:hidden text-center"
               href="#filter">
                <button className="mx-auto inline-block text-xl px-8 py-2 rounded-3xl bg-gray-300 shadow-lg ">Filter
                    <i className="inline-block">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M8 7l4-4m0 0l4 4m-4-4v18"></path>
                        </svg>
                    </i>
                </button>
            </a>
            {!isEmptySearch && <QueryClientProvider client={queryClient}>
                <DoQuery filters={searchFilters} updateFilterAction={updatePostcodeFilter}
                         increaseRadiusAction={increaseRadius}/>
            </QueryClientProvider>}

        </div>

    );
}

function NotFoundErrorMsg() {
    return <p className="text-red-500 mt-16 text-center text-p18px">
        Leider konnte keine Apotheke nach Ihren Vorgaben gefunden werden. Vielleicht suchen Sie im Umkreis von einem
        anderen Ort oder schreiben Sie uns einfach: <a
        href="mailto:service@belsana.de">service@belsana.de</a> Wir suchen die nächstliegende Apotheke für Sie heraus.
        <br/>
        Vielen Dank,
        <br/>
        Ihr Belsana Team

    </p>;
}

/*function setGmapsToEnabled() {
    console.log('setGmapsToEnabled()');
    setGmapsIsEnabled(true);
}*/

function DoQuery({filters, updateFilterAction, increaseRadiusAction}) {
    /*    const [pseudo, setPseudo] = useState(false);
        const [place, setPlace] = useState('');*/
    const [isMobile, setIsMobile] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [currentRadius, setCurrentRadius] = useState(5);
    const [gmapsIsEnabled,setGmapsIsEnabled] = useState(false);
    const placeRef = useRef();

    const fetchLocations = async () => {
        //console.log('!DoQuery.fetchLocations() filters.OrtPLZ', filters.OrtPLZ);
        // const apiUrl = "https://belsana-de-staging.cluster.belsana.de/de/Ajax/getAjaxData";
        const apiUrl = window.location.href.includes('localhost') ? "https://belsana-de-staging.cluster.belsana.de/de/Ajax/getAjaxData" : "/de/Ajax/getAjaxData";
        //  const apiUrl = "./locations-data.json";
        //console.log('!!!ApiUrl', apiUrl);
        const searchParams = new FormData();
        let distance = filters.distance;
        if (distance < currentRadius) {
            distance = `${currentRadius}km`;
        }
        searchParams.append('origin', filters.OrtPLZ);
        searchParams.append('distance', distance);
        searchParams.append('variant', 'storefinder');
        const res = await fetch(apiUrl, {
            method: "POST", body: searchParams,
        });
        return res.json();
    }

    const [mapContainer, setMapContainer] = useState(null);

    const mapRef = useCallback((node) => {
        node && setMapContainer(node);
    }, []);

    const mapOptions = {
        center: {lat: 51.508742, lng: -0.120850}, zoom: 5, maxZoom: 15, styles: mapStyles
    };

    const filteredLocations = useCallback((unfilteredData) => {
        //console.log('useCallback filteredLocations(unfilteredData)',unfilteredData);
        if (unfilteredData === undefined) return [];
        if (unfilteredData !== undefined && status !== 'ERROR' && !Array.isArray(unfilteredData) && unfilteredData['flag'] === 'list') {
            // console.log('unfilteredData is not Array - list of cities');
            return unfilteredData;
        }
        const prefilteredData = unfilteredData.filter((location) => {
            if (filters['flachStrich'] && !filters['messystem3D']) {
                return location.flachstrick === 'J';
            }
            if (filters['messystem3D'] && !filters['flachStrich']) {
                return location.messsystem === 'J';
            }
            if (filters['messystem3D'] && filters['flachStrich']) {
                return location.messsystem === 'J' && location.flachstrick === 'J';
            }
            return true;
        });
        //   console.log('prefilteredData',prefilteredData);
        if (Array.isArray(prefilteredData)) {
            return prefilteredData;
        }

        return [];
    }, [filters]);

    useEffect(() => {
        //console.log('DoQuery MOUNT useEffect []',currentRadius);
        setIsMobile(window.innerWidth < 768);
        setGmapsIsEnabled(checkDSVGOCookies);
    }, []);

    useEffect(() => {
        //console.log('useEffect [currentRadius]',currentRadius);
        if (currentRadius > 5 && currentRadius <= 40) {
            increaseRadiusAction(currentRadius);
        }
    }, [currentRadius]);

    const {data, status} = useQuery(['locations', filters], fetchLocations);

    useEffect(() => {
        console.log('useEffect [data]', data);
        if (data) {
            //console.log('useEffect [data]',data);
            setFilteredData(filteredLocations(data.data));
        }
    }, [data, filters]);

    const checkDSVGOCookies = () => {
        console.log('checkDSVGOCookies()');
        // Get the cookie string
        const cookieString = document.cookie;
        console.log('cookieString', cookieString);
        // Find the "CookieConsent" cookie
        const cookieConsentStart = cookieString.indexOf("CookieConsent=");
        console.log('cookieConsentStart', cookieConsentStart);
        if (cookieConsentStart !== -1) {
            const cookieConsentEnd = cookieString.indexOf(";", cookieConsentStart);
            console.log('cookieConsentEnd', cookieConsentEnd);
            const cookieConsentValue = cookieString.substring(cookieConsentStart + "CookieConsent=".length, cookieConsentEnd === -1 ? undefined : cookieConsentEnd);
            console.log('cookieConsentValue', cookieConsentValue);
            console.log(' decodeURIComponent(cookieConsentValue)', decodeURIComponent(cookieConsentValue));

            console.log('--------------');
            const inputString = decodeURIComponent(cookieConsentValue);
            // Regular expression to match the value after "marketing:"
            const regex = /marketing:(\w+)/;
            const match = inputString.match(regex);

            if (match && match[1] === "true") {
                console.log("The value after 'marketing:' is 'true'");
                return true;
            } else {
                console.log("The value after 'marketing:' is not 'true'");
                return false;
            }

        } else {
            console.log("CookieConsent cookie not found");
            return false;
        }

    }

    if (status === 'loading') {
        return <div className="text-belsana pt-20 text-center flex justify-center items-center">
            {/*Daten werden geladen ...*/}
            <DotLoader color={"rgb(55, 89, 136)"} loading={true} size={60}/>
        </div>
    }

    if (status === 'error') {
        console.warn('ERROR!!!!');
        return <div className="text-red-700">Error: </div>
    }

    if (data.status === 'ERROR' || filteredLocations(data.data).length < 6) {

        if (currentRadius < 20) {
            console.warn('-------------------------we have no results increasing radius to ', currentRadius + 5);
            setCurrentRadius(currentRadius + 5);
        } else if (currentRadius >= 20 && currentRadius <= 30) {
            console.warn('-------------------------we have no results increasing radius to ', currentRadius + 10);
            setCurrentRadius(currentRadius + 10);
        }
    }


    const updatePostcodeFilter = (postcode) => {
        //console.log('updatePostcodeFilter(postcode)',postcode);
        updateFilterAction(postcode);
    }

    return (<div>

        {data.status === 'ERROR' && <div className="container mx-auto">
            <NotFoundErrorMsg/>
        </div>}
        {data.status === 'OK' && <div className="">
            {(data.status === 'OK' && (filteredData.length > 0) && filteredData[0].location !== undefined) &&
                <div className="lg:flex gap-4 w-full relative">
                    {(!isMobile && gmapsIsEnabled) && <GoogleMapsProvider
                        googleMapsAPIKey={GOOGLE_MAPS_API_KEY}
                        mapContainer={mapContainer}
                        mapOptions={mapOptions}
                        onLoad={(map) => map.setZoom(4)}
                    >
                        <div className="w-full mt-16 lg:w-1/2 hidden md:block ">
                            <LocationsMap dataProvider={filteredData}/>
                            <React.StrictMode>
                                <div className="map-container lg:sticky top-168 lg:z-50 lg:w-full">
                                    <MapCanvas ref={mapRef}/>
                                </div>
                            </React.StrictMode>
                        </div>
                    </GoogleMapsProvider>}
                    {(!isMobile && !gmapsIsEnabled ) &&

                        <div className="w-full mt-16 lg:w-1/2 hidden md:block ">
                            <div className="warning-gmaps-not-enabled">
                                <p className="text-center text-red-600">
                                    Die Karte kann nicht angezeigt werden, da die Marketing-Cookies nicht
                                    akzeptiert wurden.
                                    <br/>
                                    <button
                                        onClick={() => {
                                            // DEBUG start
                                            console.log('renewing cookies, window.CookieBot', window.Cookiebot);

/*                                            window.addEventListener('cookieBotAccepted', (event) => {
                                                console.log('window eventListener: synthetic cookieBotAccepted event...', event);
                                                setGmapsIsEnabled(true);
                                                console.log(' -- : gmapsIsEnabled = ', gmapsIsEnabled);
                                            }, {once: false});
                                            const timeOut1 = setTimeout(() => {
                                                console.log('timeout1 fired');
                                                const pseudoEvent = new Event('cookieBotAccepted');
                                                window.dispatchEvent(pseudoEvent);
                                                console.log('pseudo Event dispatched', 'cookieBotAccepted');
                                            }, 4 * 1000);*/
                                            // DEBUG end
                                            if (window.Cookiebot) {
                                                window.Cookiebot.renew();
                                                window.addEventListener('CookiebotOnAccept', (event) => {
                                                    console.log('CookieConsent CookiebotOnAccept event...', event);
                                                   // window.location.reload();
                                                    setGmapsIsEnabled(true);
                                                }, {once: true});
                                                window.addEventListener('CookiebotOnDecline', (event) => {
                                                    console.log('CookieConsent CookiebotOnDecline event...', event);
                                                    //window.location.reload();
                                                }, {once: true});


                                            }
                                        }}
                                        className={'mx-auto mt-4 block border border-r-0 border-t-0 border-b-0 bg-belsana border-l border-belsana border-opacity-25 text-center '}>
                                                                                                    <span
                                                                                                        className="block text-white px-14 py-4 no-underline">
                                                    Marketing-Cookies akzeptieren und Karte anzeigen
                                                </span>
                                    </button>

                                </p>

                            </div>
                        </div>

                    }
                    <div className=" w-full lg:w-1/2 mt-2">
                        <LocationsGrid dataProvider={filteredData}/>
                    </div>
                </div>}
            {(data.status === 'OK' && filteredData.length === 0) &&
                <div className="mt-8 container px-4 mx-auto text-red-500 w-full text-center text-p18px">
                    {currentRadius < 40 && <>
                        <NotFoundErrorMsg/>
                    </>}
                    {currentRadius >= 40 && <>
                        <NotFoundErrorMsg/>
                    </>}
                </div>}
            {(data['flag'] === 'list') && <div className="pt-14">
                <h3 className="text-2xl text-belsana my-8">
                    Wir haben mehrere Ergebnisse gefunden.
                    Bitte wählen Sie eine Stadt aus oder geben Sie oben eine Postleitzahl ein.
                </h3>
                <div className="grid gap-4 lg:grid-cols-3 pb-28 ">
                    {Object.entries(data.data).map((entry, index) => {
                        if (entry[1] !== 'list') {
                            return (<div key={index} onClick={() => updatePostcodeFilter(entry[1].zip_min)}
                                         className="bg-white p-4 rounded cursor-pointer hover:bg-belsana focus:bg-belsana hover:text-white focus:text-white">
                                <p>{entry[1].zip_min}</p>
                                <p><strong>{entry[1].name}</strong></p>
                            </div>);
                        }
                    })}
                </div>
            </div>}
        </div>}
    </div>);
}

export default AppBelsanaApothenFinder;
