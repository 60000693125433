import React, {useEffect, useState} from 'react';
import {useGoogleMap} from '@ubilabs/google-maps-react-hooks';

function LocationsMap({dataProvider}) {

    const map = useGoogleMap();

    const [markers, setMarkers] = useState([]);
    const [markerPins, setMarkerPins] = useState([]);
    const [initLocation, setInitLocation] = useState({});
    const [activeMarker, setActiveMarker] = useState(null);

    //const point = {lat: -25.344, lng: 131.036};

/*    useEffect(() => {
        if (map) {
            //console.log('effect markers[] We have a map', map);
        }
    }, [markers]);*/

    useEffect(() => {
        //console.log('LocationsMap useEffect initLocation', initLocation);
        if (map) {
            if (typeof initLocation.lat === 'number') {
                map.setCenter(initLocation);
            }
            updateMarkerPins();
        }
    }, [initLocation]);

    useEffect(() => {
        //console.log('effect [activeMarker]', activeMarker);
    }, [activeMarker]);

    const selectCardForMarker = (id) => {
        //console.log('selectCardForMarker(id)', id);
        //  const selectedCard = document.querySelector(`[data-kundennr="${id.trim()}"]`);
        const selectedCard = document.querySelector('#root').shadowRoot.querySelector(`[data-kundennr="${id.trim()}"]`);
        // document.querySelector('.selected-card') && document.querySelector('.selected-card').classList.remove('selected-card');
       document.querySelector('#root').shadowRoot.querySelector('.selected-card') && document.querySelector('#root').shadowRoot.querySelector('.selected-card').classList.remove('selected-card');
        selectedCard.classList.add('selected-card');
        selectedCard.scrollIntoView({
            block: 'center',
            behavior: 'smooth'
        });
        //console.log('selectedCard', selectedCard);
    }

    function updateMarkerPins() {
        const tmpMarkersPins = [];
        let bounds = new window.google.maps.LatLngBounds();
        if (markers.length > 0) {

            markers.map((markerPos) => {
                //console.log('markerPos:',markerPos);
                const marker = new window.google.maps.Marker({
                    position: markerPos,
                    map,
                    icon: markerPos.icon,
                    locationId: markerPos.locationObjId
                });
                window.google.maps.event.addListener(marker, 'click', (e) => {
                    //console.log('marker click event,marker.locationId', marker.locationObjId);
                    //console.log('marker click event:marker:', marker);
                    //console.log('marker click event:marker.icon:', marker.icon);
                    //console.log('activeMarker:',activeMarker);
                    if(activeMarker!==null) {
                        if(activeMarker.icon.includes('vcf-pin')){
                            activeMarker.setIcon('./img/vfc-pin.svg');
                        } else{
                            activeMarker.setIcon('./img/apo-pin.svg');
                        }
                        //console.log('reseting icon of',activeMarker);
                    }
                    if(window.lastMarker) {
                        if(window.lastMarker.icon.includes('vfc')){
                            window.lastMarker.setIcon('./img/vfc-pin.svg');
                        } else{
                            window.lastMarker.setIcon('./img/apo-pin.svg');
                        }
                        //console.log('reseting icon of',window.lastMarker);
                    }
                    let activeIconImage ='./img/pin-apo-alt.svg';
                    //console.log('marker.icon',marker.icon,typeof marker.icon);
                    const currentIcon= marker.icon;
                    //console.log('currentIcon',currentIcon,currentIcon.includes);
                    //console.log('currentIcon.includes(\'vfc\')',currentIcon.includes('vfc'));
                    if(currentIcon.includes('vfc')){
                        activeIconImage ='./img/pin-vfc-alt.svg';
                    }
                    marker.setIcon(activeIconImage);
                    setActiveMarker(marker);
                    window.lastMarker = marker;
                    // TODO change icon on click
                    // http://jsfiddle.net/zsw76pq9/
                    //  marker.setIcon('https://www.google.com/mapfiles/marker_green.png')
                    selectCardForMarker(marker.locationId);
                });
                tmpMarkersPins.push(marker);
                bounds.extend(marker.position);
                return markerPos;
            });
        }
        setMarkerPins([...tmpMarkersPins]);
        if (typeof initLocation.lat === 'number') {
            map.setCenter(initLocation);
        }

        map.fitBounds(bounds);

    }

    useEffect(() => {
        if (map) {
            updateMarkerPins();
        }
    }, [map]);

    useEffect(() => {
        //console.log('LocationsMap  useEffect(dataProvider) ', dataProvider);

        // delete old markers
        deleteAllMarkerPins();

        if (!dataProvider) return;

        let tmpMarkers = [];
        dataProvider.map((locationObj, index) => {
            const markerIcon = locationObj.vfc === '5' ? './img/vfc-pin.svg' : './img/apo-pin.svg';
            const markerPos = {
                lat: parseFloat(locationObj.location.lat),
                lng: parseFloat(locationObj.location.lon),
                icon: markerIcon,
                locationObjId: locationObj['kundennummer']
            };
            tmpMarkers.push(markerPos);
            return locationObj;
        });
        setMarkers(tmpMarkers);
        setInitLocation({
            lat: parseFloat(dataProvider[0].initialLat),
            lng: parseFloat(dataProvider[0].initialLon),
        });

    }, [dataProvider]);

    function deleteAllMarkerPins() {
        markerPins.map((markerPin) => {
            markerPin.setMap(null);
            return markerPin
        });
        setMarkerPins([]);
    }

    return (
        <> </>
    );
}

export default LocationsMap;